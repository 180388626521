// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inperson-js": () => import("./../../src/pages/inperson.js" /* webpackChunkName: "component---src-pages-inperson-js" */),
  "component---src-pages-ourstory-js": () => import("./../../src/pages/ourstory.js" /* webpackChunkName: "component---src-pages-ourstory-js" */),
  "component---src-pages-registry-js": () => import("./../../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-virtual-js": () => import("./../../src/pages/virtual.js" /* webpackChunkName: "component---src-pages-virtual-js" */)
}

